<template>    
    
    <div class='user-box'>
        <div class="header">
            <div class="avatar" >
                <slot name="avatar"></slot>
            </div>
            <div class="title">
                <slot name="title"></slot>
            </div>            
        </div>
        <div class="content">
            <slot name="content"></slot>
        </div>
        <div class="footer">
            <slot name="footer"></slot>
        </div>

    </div>
</template>

<script>
    export default {
        name: "UserBox",   
        props: ["icon", "background"],
        data () {
            return {
                
            }
        },        
        methods: {
            
        },
        async mounted() {

        }
    };
</script>

<style lang="less" scoped>
    .user-box{
        position: relative;
        .header{
            .avatar{
                top: -35px;
                position: absolute;
                width: 85px;
                height: 85px;
                overflow: hidden;
                z-index: 5;
                border-radius: 4px;

               
            }
        }
        .content{
            text-align: right;
            position: relative;
            color: #3c4858;
            font-size:15px;
        }
    }
  
</style>
