<template>    
    
    <div class='simple-box' :class="{full:isFull}">
        <div class="header">
            <slot name="title"></slot>
        </div>
        <div class="content">
            <slot name="content"></slot>
        </div>
        <div class="footer">
            <slot name="footer"></slot>
        </div>

    </div>
</template>

<script>
    export default {
        name: "SimpleBox",   

        props: {
            icon:{
                required:false
            },
            background:{
                required:false
            },
            isFull: {
                type: Boolean,
                required:false,
                default:false
            }        
        },
        data () {
            return {
                
            }
        },        
        methods: {
            
        },
        async mounted() {

        }
    };
</script>

<style lang="less" >
    .simple-box{
        font-family: Roboto,Helvetica,Arial,sans-serif;
        font-weight: 300;
        padding: 15px 30px;
        min-width: 780px;
        // background: #eee;
        position: relative;
        box-shadow: 0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2);
        border-radius: 3px;


        .header{
            text-align: right;
            color: #999;
            font-size: 14px;
            .floating-icon {
                position: absolute;
                background-color:#00acc1;
                box-shadow: 0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2);
                border-radius: 3px;
                padding: 15px;
                font-size: 36px!important;
                line-height: 56px;
                width: 85px;
                height: 85px;
                left:30px;
                top:-20px;
                text-align: center;
                color: white;
            }
        }
        .content{
            text-align: right;
            position: relative;
            color: #3c4858;
            font-size:15px;
            @media(min-width:1200px){
                font-size:25px;
            }
            padding-bottom: 30px;
         
        }       
    }
    .full{
        height: calc(100vh - 140px);            
    }
  
</style>
