<template>
  <base-page>
    <titlu-pagina Titlu="Profil utilizator" :AdaugaVisible='false' />
    <el-card>
        <el-row :gutter="15">
            <el-col style="margin-top:30px;" :span="15">
                <div>
                    <simple-box icon='el-icon-user-solid' background='#43a047'>
                        <template v-slot:title>
                            <user-box background='#43a047'>                        
                                <template v-slot:avatar>
                                    <el-upload                                   
                                            :data="uploadData"
                                            :action="BASE_URL + 'utilizatori/uploadAvatar'" 
                                            :headers="Headers"
                                            :on-success="handleSuccessAvatar"
                                            list-type="picture-card" 
                                            :auto-upload="true" 
                                            name="poza_profil"
                                            :before-upload="beforeUpload">
                                        <div slot="file" slot-scope="{file}">
                                            <img class="el-upload-list__item-thumbnail" :src="file.url" >
                                            <span class="el-upload-list__item-actions">
                                                <span
                                                    class="el-upload-list__item-delete"
                                                    @click="handleRemove(file)">
                                                    <i class="el-icon-delete"></i>
                                                </span>
                                            </span>
                                        </div>
                                        <img :src="selectedObject.Item.AvatarURL" class="profilePic" onerror="this.onerror=null; this.src='@/assets/default.jpg'"/>
                                    </el-upload>
                                </template>
                            </user-box>                      

                            <h2>Modificare profil</h2>

                        </template>
                        <template v-slot:content>
                            <div class="container-fluid">
                                <el-form label-position="top" :inline="false" label-width="100%"  :model='selectedObject'  ref='my-form' v-loading="loadingVisible">            
                                    <el-row :gutter="15" >                
                                        <el-col :span='7'>
                                            <el-form-item label='Nume'>
                                                <el-input  class='full-width' v-model='selectedObject.Item.Nume' />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span='7'>
                                            <el-form-item label='Email'>
                                                <el-input  class='full-width' v-model='selectedObject.Item.Email' />
                                            </el-form-item>
                                        </el-col>  
                                        <el-col :span="5">
                                            <el-button style="margin-top:60px" type="success" @click="save()">Actualizare</el-button>
                                        </el-col>              
                                        <el-col :span="4">
                                            <el-button type="primary" style="margin-top:60px" @click="change_password()">Schimba parola</el-button>
                                        </el-col>                   
                                    </el-row>

                                    <el-row :gutter="15">
                                        <el-col :span="7">
                                             <el-form-item label='Functie'>
                                                <el-input  class='full-width' v-model='selectedObject.Item.NumeFunctie' disabled/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span='7'>
                                            <el-form-item label='Data Incepere'>
                                                <el-input disabled class='full-width' v-model='selectedObject.Item.DataIncepere' />
                                            </el-form-item>
                                        </el-col>     
                                        <el-col :span="10">
                                            <el-form-item label='Semnatura' >
                                                <VueSignaturePad style="background-color: white; border-radius: 4px; border: 1px solid #DCDFE6; margin-bottom: 18px;" width="320px" height="160px" :options="{ penColor: 'rgb(58,132,210)'}" class="border-signature" ref="signaturePad" />
                                                <div class="row" style="margin:0; padding: 0; width: 100%">
                                                    <div class="col-12">
                                                        <el-button style="width: 100%" type="warning" plain @click="undo" >Corecteaza</el-button>
                                                    </div>
                                                    <!-- <div class="col-6">
                                                        <el-button style="width: 100%" type="success" plain @click="undo" >Salveaza</el-button>
                                                    </div> -->
                                                    
                                                </div>         
                                            </el-form-item>  
                                        </el-col>  
                                        <!-- <el-col :span="5">
                                             <el-form-item label='Departamente'>
                                                <el-dropdown>
                                                    <el-button type="primary">
                                                        Afiseaza
                                                    </el-button>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <el-dropdown-item
                                                            v-for="departament in selectedObject.Item.Departamente"
                                                            :key="departament.Nume">
                                                            {{departament.Nume}}
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </el-form-item>   
                                        </el-col> -->
                                        <!-- <el-col :span="4">
                                             <el-form-item label='Studii'>
                                                <el-dropdown>
                                                    <el-button type="primary">
                                                        Afiseaza
                                                    </el-button>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <el-dropdown-item
                                                            v-for="departament in selectedObject.Item.Departamente"
                                                            :key="departament.Nume">
                                                            {{departament.Nume}}
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </el-form-item>
                                        </el-col> -->
                                    </el-row>

                                    <el-row :gutter="15" >                
                                        <el-col :span='7'>
                                            <el-form-item label='Telefon'>
                                                <el-input  class='full-width' v-model='selectedObject.Item.Telefon' />
                                            </el-form-item>
                                        </el-col>          
                                    </el-row>
                                </el-form>  
                            </div>
                        </template>
                    </simple-box>
                </div>
            </el-col>
            <el-col class="cvCard" :span="8"> 
                <div
                    class="container-fluid">
                    <el-upload
                        :data="uploadData"
                        class="upload-demo"
                        :headers="Headers"
                        :on-success="handleSuccessCV"
                        drag
                        :auto-upload="true"
                        name="cv"
                        :thumbnail-mode="true" 
                        :action="BASE_URL + 'utilizatori/upload_cv'"
                        multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">Trage CV-ul aici sau<em> fa click pentru upload</em></div>
                        <div class="el-upload__tip" slot="tip">PDF files with a size less than 500kb</div>
                    </el-upload>    
                </div>   
                <div v-if="selectedObject.Item.CV_URL != '' && selectedObject.Item.CV_URL  != null">
                    <div class="cvControls">
                        <div><el-button type="success" @click="openCV()">Vizualizare CV</el-button></div>
                        <div><el-button type="danger" @click="handleRemoveCV()">Stergere CV</el-button></div>
                    </div>
                </div>            
            </el-col>
        </el-row>
    </el-card>
    <change-password-dialog ref="changePassDlg"></change-password-dialog>

  </base-page>
</template>

<script>
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import settings from "@/backend/LocalSettings";
import UserBox from '@/components/UserBox';
import SimpleBox from '@/components/SimpleBox';   
import ChangePassword_dialog from '@/pages/ChangePassword_dialog';
import VueSignaturePad from "vue-signature-pad";

export default {
    name: 'Profil_utilizator',
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina,
        'user-box'  : UserBox,
        'simple-box'  : SimpleBox,   
        'change-password-dialog': ChangePassword_dialog,
        VueSignaturePad
    },
    data(){
        return {
            selectedObject: {Item: {Nume:'', Email: '', Functie: '', Departamente: [], Studii: [], NumarLegitimatie: '', AvatarURL:'', CV_URL: '', Semnatura: '' }},
            loadingVisible: false,
            dialogVisible: false,
            uploadData: { userId: settings.get_user_id() },
            BASE_URL: settings.BASE_URL,
            Headers: {'Token': settings.get_token()}
        }
    },
    methods: {
        async get_info(){        
            var result          = await this.post("utilizatori/get_info_item_dialog", { id: settings.get_user_id() } )                    
            this.selectedObject = result;
            this.$refs.signaturePad.fromDataURL(this.selectedObject.Item.Semnatura)
        },
        change_password: function(){
            this.$refs['changePassDlg'].show_me();

        }, 
        undo() {
            this.$refs.signaturePad.undoSignature()
        },
        handleSuccessAvatar() {
            this.$router.go(0);
        },
        handleSuccessCV() {
            this.get_info();
        },
        async handleRemove() {
            this.selectedObject.Item.AvatarURL = '';
            await this.post("utilizatori/delete_avatar", {object: this.selectedObject.Item});

        },
         async handleRemoveCV(){
            this.selectedObject.Item.CV_URL = '';
            await this.post("utilizatori/delete_cv", {object: this.selectedObject.Item});

        },
        openCV() {
            window.open(this.selectedObject.Item.CV_URL)
        },
        handlePreview(file) {
            this.dialogVisible = true;

        },
        save: async function() {
            const { isEmpty, data }         = this.$refs.signaturePad.saveSignature()                
            this.selectedObject.Item.Semnatura = data;
            await this.post("utilizatori/update", { object: this.selectedObject.Item} );

        },
        remove(file){
            console.log(file);
        },
        beforeUpload(file) {            
            var pictures    = ["image/jpg","image/png","image/jpeg","image/gif"];                
            const isJPG     = pictures.includes(file.type);
            const isLt2M    = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('Sunt permise doar imagini!');
            }
            if (!isLt2M) {
                this.$message.error('Imaginea depaseste 2MB!');
            }
            return isJPG && isLt2M;

        },
        beforeAvatarUpload (file) {
            const isLt2M = file.size / 1024 / 1024 < 4
            if (!isLt2M) {
                this.$message.error('Dimensiunea maxima este 4MB!')
            }
            return isLt2M
        }
    },
    mounted(){
        this.get_info()
    }
}
</script>

<style scoped>

.upload-demo {
   display: block;
   margin-left: auto;
   margin-right: auto;
   text-align: center;
}
.cvControls {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    margin: 10px;
    
}
.cvTitle {
    display: inline-block;
    line-height: 1;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    text-align: center;
    box-sizing: border-box;
    font-weight: 500;
    padding: 20px 20px;
    font-size: 14px;
    border-radius: 4px;
}

.profilePic {
    height: 85px;
    margin-top: -65px;
    margin-left: -65px;
}

.cvCard {
    font-family: Roboto,Helvetica,Arial,sans-serif;
    min-width: fit-content;
    position: relative;
    right: 8px;
    font-weight: 300;
    padding: 15px 30px;
    position: absolute;
    box-shadow: 0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2);
    border-radius: 3px;
    margin-top: 30px;
}

.el-button {
    min-width: 135px;
}

</style>